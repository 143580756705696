import _ from "lodash";
import get from "lodash/get";
import moment from "moment";
import { countries } from "countries-list";

export const selectAccount = (state) => get(state, "login", null);
export const selectNewAccount = (state) => get(state, "registration", null);
export const selectSessionFromCookies = (state) => get(state, "session", null);
export const selectAllEvents = (state) => get(state, "allEvents", null);
export const selectCurrentRaceId = (state) =>
  get(state, "appState.currentRaceId", null);
export const selectShowRaceCard = (state) =>
  get(state, "appState.showRaceCard", null);
export const selectGuestUser = (state) =>
  get(state, "appState.guestUser", false);
export const selectEventsFilters = (state) =>
  get(state, "eventsFiltering", null);
export const selectEventsFilteringList = (state) => {
  const rules = get(state, "eventsFiltering", null);
  const allEvents = get(state, "allEvents", null);

  if (_.isEmpty(_.omitBy(rules, (rule) => _.isNil(rule) || _.isEmpty(rule)))) {
    return allEvents;
  }

  const countriesByContinent = _.chain(countries)
    .pickBy(({ continent }) => _.includes(rules.continent, continent))
    .keys()
    .value();

  return _.chain(allEvents)
    .filter(
      ({ country_code, event_start_date, event_type, series, title } = {}) =>
        _.every([
          rules.name
            ? title.toLowerCase().includes(rules.name.toLowerCase())
            : true,
          !_.isEmpty(rules.dates)
            ? new Date(event_start_date).getTime() >=
                new Date(`1 ${rules.dates[0]}`).getTime() &&
              new Date(event_start_date).getTime() <=
                new Date(
                  `1 ${rules.dates[0]}` === `1 ${rules.dates[1]}`
                    ? moment(`1 ${rules.dates[1]}`).add(1, "month")
                    : `1 ${rules.dates[1]}`
                ).getTime()
            : true,
          !_.isEmpty(countriesByContinent)
            ? _.includes(countriesByContinent, country_code)
            : true,
          !_.isEmpty(rules.distance)
            ? _.includes(rules.distance, event_type)
            : true,
          !_.isEmpty(rules.series) ? _.includes(rules.series, series) : true,
        ])
    )
    .compact()
    .value();
};

export const selectEventComments = (state) =>
  get(state, "eventComments[" + [selectCurrentRaceId(state)] + "]");

export const selectUserId = (state) => get(state, "userId.id");

export const selectUserInfo = (state) => get(state, "userInfo");

export const selectUserResults = (state) => get(state, "userResults");

export const selectUpcomingEvents = (state) => get(state, "upcomingEvents");

export const selectUpdateProfileInfoStatus = (state) =>
  get(state, "updateProfileInfo.status");
