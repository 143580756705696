import { createSlice } from "@reduxjs/toolkit";

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    loggedIn: false,
    currentRaceId: null,
    showRaceCard: false,
    guestUser: false,
  },
  reducers: {
    setLoggedIn: (state, action) => {
      return { ...state, loggedIn: action.payload.loggedIn };
    },
    setCurrentRace: (state, action) => {
      return { ...state, currentRaceId: action.payload.currentRaceId };
    },
    setShowRaceCard: (state, action) => {
      return { ...state, showRaceCard: action.payload.showRaceCard };
    },
    setGuestUser: (state, action) => {
      return { ...state, guestUser: action.payload.guestUser };
    },
  },
});

// this is for dispatch
export const { setLoggedIn, setCurrentRace, setShowRaceCard, setGuestUser } =
  appStateSlice.actions;

// this is for configureStore
export default appStateSlice.reducer;
