import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EnduricInput from "../../../components/EnduricInput";
import Select from "react-select";
import { COUNTRIES } from "../../../consts";
import { addUserResult } from "../../../redux-slice/async-slice/addUserResult";
import Loading from "../../../components/Loading/Loading";
import { selectSessionFromCookies, selectUserId } from "../../../selectors";
import { getUserResults } from "../../../redux-slice/async-slice/getUserResults";

const AddResult = ({ changePopUpState }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector(selectSessionFromCookies);
  const userId = useSelector(selectUserId);

  const [formData, setFormData] = React.useState({
    event_type: null,
    event_country: "",
    event_city: "",
    event_series: "",
    event_title: "",
    event_date: "",
    overall: "",
    swim: "",
    bike: "",
    run: "",
    t1: "",
    t2: "",
    formtype: "add",
  });

  const timeRegex = "^(?:(?:([01]?\\d|2[0-9]):)?([0-9]?\\d):)?([0-9]?\\d)$";

  const handleEventTypeSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      event_type: selectedOption,
    }));
  };
  const handleEventCountrySelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      event_country: selectedOption,
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const distanceOptions = [
    { label: "ironman", value: "ironman" },
    { label: "ironman70.3", value: "ironman70.3" },
    { label: "olimpic", value: "olimpic" },
    { label: "sprint", value: "sprint" },
    { label: "half marathon", value: "half-marathon" },
    { label: "marathon", value: "marathon" },
    { label: "other", value: "other" },
  ];

  const formSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const response = await dispatch(
      addUserResult({
        payload: {
          token: token,
          requestData: {
            ...formData,
            event_type: formData.event_type.value,
            event_country: formData.event_country.value,
          },
        },
      })
    );

    if (response.payload.success === "ok") {
      await dispatch(getUserResults({ userId }));
      changePopUpState(false);
    }
  };

  return (
    <div className="add-result-component">
      {loading && <Loading />}
      <form onSubmit={formSubmit}>
        <Select
          className={"distance-select add-result-select"}
          name="event_type"
          options={distanceOptions}
          value={formData.event_type}
          onChange={handleEventTypeSelectChange}
        />
        <Select
          className={"country-select add-result-select"}
          name="event_country"
          options={COUNTRIES.map((item) => ({
            label: item.name,
            value: item.name,
          }))}
          value={formData.event_country}
          onChange={handleEventCountrySelectChange}
        />
        <EnduricInput
          label={"City"}
          type={"text"}
          placeholder="City"
          name="event_city"
          value={formData.event_city}
          onChange={handleInputChange}
        />
        <EnduricInput
          label={"Date"}
          type={"date"}
          placeholder="Date"
          name="event_date"
          value={formData.event_date}
          onChange={handleInputChange}
        />
        <EnduricInput
          label={"Event title"}
          type={"text"}
          placeholder="Event title"
          name="event_title"
          value={formData.event_title}
          onChange={handleInputChange}
        />
        <EnduricInput
          label={"Event series"}
          type={"text"}
          placeholder="Event type"
          name="event_series"
          value={formData.event_series}
          onChange={handleInputChange}
        />
        <fieldset>
          <h3>Swim</h3>
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Swim Time"
            name="swim"
            value={formData.swim}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Transition 1"
            name="t1"
            value={formData.t1}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
        </fieldset>
        <fieldset>
          <h3>Bike</h3>
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Bike Time"
            name="bike"
            value={formData.bike}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Transition 2"
            name="t2"
            value={formData.t2}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
        </fieldset>
        <fieldset>
          <h3>Run</h3>
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Run Time"
            name="run"
            value={formData.run}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
          <EnduricInput
            required={false}
            pattern={timeRegex}
            label="Overall"
            name="overall"
            value={formData.overall}
            onChange={handleInputChange}
            placeholder="hh:mm:ss"
          />
        </fieldset>
        <button className="enduric-button red submit">post result</button>
      </form>
    </div>
  );
};

export default AddResult;
