import React from "react";
import GradesStars from "../Reviews/GradesStars";

const CommentItem = ({ commentData }) => {
  return (
    <div className="comment-component">
      <div className="comment-component-item">
        <div className="comment-header">
          <span className="comment-topic">Swim</span>
          <div className="grades-stars">
            <GradesStars grades={commentData.swim_course_rate} />
          </div>
        </div>
        <p className="comment-body">{commentData.swim_course_comment}</p>
      </div>

      <div className="comment-component-item">
        <div className="comment-header">
          <span className="comment-topic">Bike</span>
          <div className="grades-stars">
            <GradesStars grades={commentData.bike_course_rate} />
          </div>
        </div>
        <p className="comment-body">{commentData.bike_course_comment}</p>
      </div>

      <div className="comment-component-item">
        <div className="comment-header">
          <span className="comment-topic">Run</span>
          <div className="grades-stars">
            <GradesStars grades={commentData.run_course_rate} />
          </div>
        </div>
        <p className="comment-body">{commentData.run_course_comment}</p>
      </div>

      <div className="comment-component-item">
        <div className="comment-header">
          <span className="comment-topic">Overall</span>
        </div>
        <p className="comment-body">{commentData.overall_comment}</p>
      </div>
    </div>
  );
};

export default CommentItem;
