import React from "react";
import { closeIcon } from "../Icons";

const PopUp = ({ children, title , showPopUp = false, changePopUpState}) => {

  if (!showPopUp) return null;

  return (
    <div className="pop-up pop-up-container">
      <div className="pop-up-content">
        <span className="pop-up-close" onClick={() => changePopUpState(false)}>{closeIcon}</span>
        <h3 className="pop-up-title">{title}</h3>
        {children}
      </div>
    </div>
  );
};

export default PopUp;
