import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  setCurrentRace,
  setShowRaceCard,
} from "../../redux-slice/appStateSlice";
import { selectCurrentRaceId } from "../../selectors";
import defaultLogo from "../../static/images/default-event-logo.jpg";

import RaceStatus from "../RaceStatus";
import { EVENT_TYPE } from "../../consts";
import { shareIcon } from "../Icons";
import UpcomingToggle from "../RaceCard/components/UpcomingToggle";

const RacesListItem = ({ raceInfo, callBackFunction, isPublicProfile }) => {
  const {
    title,
    logo_url,
    event_type,
    event_start_date,
    event_id,
    city,
    country,
    shareUrl,
  } = raceInfo;

  const dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const displayedCurrentRaceId = useSelector(selectCurrentRaceId);

  const myRef = useRef(null);
  const isMobile = window.matchMedia("(pointer:coarse)").matches;
  const action = () => {
    dispatch(setCurrentRace({ currentRaceId: event_id }));
    dispatch(setShowRaceCard({ showRaceCard: true }));
    setActive(!isActive);
    !isMobile &&
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleImgLoadingError = (e) => (e.target.src = defaultLogo);

  const copyTextToClipboard = () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard
        .writeText(`${"https://app.enduric.co" + shareUrl}`)
        .then(() => {
          alert("You can paste the URLs from your clipboard.");
        });
    }
    Promise.reject("The Clipboard API is not available.");
  };

  useEffect(() => {
    if (displayedCurrentRaceId === event_id && !isActive) {
      setActive(!isActive);
    }
  }, [displayedCurrentRaceId]);

  return (
    <div
      className={`race-list-item ${
        isActive && displayedCurrentRaceId === event_id ? "active" : ""
      }`}
      ref={myRef}
      role="button"
      tabIndex={0}
      onKeyDown={callBackFunction || action}
      onClick={callBackFunction || action}
    >
      <div className="left-side">
        <img
          src={logo_url}
          onError={(e) => handleImgLoadingError(e)}
          alt="Race Logo"
        />
        <div className="base-info">
          <h3 className="race-name">{title}</h3>
          <div className="race-type">
            Triathlon • {EVENT_TYPE[event_type.toLowerCase()] || "Distance"}
          </div>
          <div className="race-location">
            {country}, {city}
          </div>
          <div className="race-data">
            {new Date(event_start_date).toDateString()}
          </div>
        </div>
      </div>
      <div className="right-side">
        <RaceStatus raceDateStart={event_start_date} />
        <i
          className="event-link"
          onClick={copyTextToClipboard}
          onKeyDown={copyTextToClipboard || action}
        >
          {shareIcon}
        </i>
        {/*<div className="race-rate">4.6</div>*/}
        {!isPublicProfile && <UpcomingToggle event_id={event_id} />}
      </div>
    </div>
  );
};

RacesListItem.propTypes = {
  raceInfo: PropTypes.shape({
    event_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    event_type: PropTypes.string.isRequired,
    event_start_date: PropTypes.string.isRequired,
  }),
};

export default RacesListItem;
