import React from "react";
import RaceStages from "./raceStages";
import WeatherInfo from "./WeatherInfo";

import { EVENT_TYPE } from "../../../consts";

const RaceDistanceInfo = ({ raceInfo }) => {
  const {
    event_type,
    avg_air_temp,
    avg_water_temp,
    airport_location,
    swim_course_profile,
    bike_course_profile,
    run_course_profile,
  } = raceInfo;
  return (
    <div className="race-distance-info">
      <div className="distance-type">
        {EVENT_TYPE[event_type.toLowerCase()] || "Distance"}
      </div>
      <RaceStages
        stagesInfo={{
          event_type,
          swim_course_profile,
          bike_course_profile,
          run_course_profile,
        }}
      />
      <WeatherInfo
        weatherInfo={{ avg_air_temp, avg_water_temp, airport_location }}
      />
    </div>
  );
};

export default RaceDistanceInfo;
