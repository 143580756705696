import React from "react";
import { useDispatch } from "react-redux";
import { setEventsFilterRules } from "../../../../redux-slice/eventsFilterRulesSlice";
import { searchIcon } from "../../../../components/Icons";

const SearchComponent = () => {
  const dispatch = useDispatch();
  const inputChange = (e) =>
    dispatch(setEventsFilterRules({ name: e.currentTarget.value }));

  return (
    <div className="enduric-input-container">
      <i className="search-icon">{searchIcon}</i>
      <input
        aria-label="input"
        onChange={inputChange}
        type="text"
        placeholder="Search for the race"
        className="race-search enduric-input"
      />
    </div>
  );
};

export default SearchComponent;
