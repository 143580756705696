import React from "react";

const RaceStatus = ({ raceDateStart }) => {
  const leftDays =
    (new Date(raceDateStart).getTime() - new Date().getTime()) /
    (1000 * 3600 * 24);
  const status = leftDays < 0 ? "close" : leftDays < 30 ? "soon" : "open";

  return <div className={`${status} race-status`}>{status}</div>;
};

export default RaceStatus;
