import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSessionFromCookies } from "../selectors";

const ProtectedRoute = ({ canBeGuest, redirectPath = "/login", children }) => {
  const { token } = useSelector(selectSessionFromCookies);

  return !token && !canBeGuest ? (
    <Navigate to={redirectPath} replace />
  ) : (
    children
  );
};

export default ProtectedRoute;
