import { createContext, useContext, useMemo, useState } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

export const ThemeContext = createContext({ mode: "", toggleTheme: () => {} });

export const useThemeContext = () => useContext(ThemeContext);

export const THEME_MODE = {
  LIGHT: "light",
  DARK: "dark",
};

export function ThemeProviders({ children }) {
  const [mode, updateMode] = useState(THEME_MODE.LIGHT);

  const colorMode = useMemo(
    () => ({
      mode,
      toggleTheme: () => {
        updateMode((prevMode) =>
          prevMode === THEME_MODE.LIGHT ? THEME_MODE.DARK : THEME_MODE.LIGHT
        );
      },
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: () => ({
                textTransform: "none",
              }),
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
