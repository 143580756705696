import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: null,
  },
  reducers: {
    setSessionToken: (state, action) => {
      const token = action.payload.token;

      return { ...state, token };
    },
  },
});

// this is for dispatch
export const { setSessionToken } = sessionSlice.actions;

// this is for configureStore
export default sessionSlice.reducer;
