import React from "react";
import { weatherPlane, weatherSun, weatherWater } from "../../Icons";

const WeatherInfo = ({ weatherInfo }) => {
  return (
    <div className="weather-info">
      {weatherInfo.avg_air_temp && (
        <div className="weather">
          {weatherSun}
          <span className="weather-type">Avg. Air Temp</span>
          <span className="weather-value">{weatherInfo.avg_air_temp} C</span>
        </div>
      )}

      {weatherInfo.avg_water_temp && (
        <div className="weather">
          {weatherWater}
          <span className="weather-type">Avg. Water Temp</span>
          <span className="weather-value">{weatherInfo.avg_water_temp} C</span>
        </div>
      )}

      {/* TODO: we dont have wind information yet
       <div className="weather">
        {weatherWind}
        <span className="weather-type">Wind Speed</span>
        <span className="weather-value">10 km/h</span>
      </div> */}

      {weatherInfo.airport_location && (
        <div className="weather">
          {weatherPlane}
          <span className="weather-type">Airport</span>
          <span className="weather-value">{weatherInfo.airport_location}</span>
        </div>
      )}
    </div>
  );
};

export default WeatherInfo;
