import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = {
};

export const addUserResult = createAsyncThunk("addUserResult", async ({ payload }) => {
  const response = await post(
    `${CONFIG.API_SERVER}/postUserResult`,
    payload.requestData,
    payload.isFormData,
    payload.token
  );
  return response;
});

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled");
}

const addUserResultSlice = createSlice({
  name: "addUserResult",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addUserResult.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(addUserResult.fulfilled, (state, action) => {
        return {
          ...state,
          status: "succeeded",
        };
      })
      .addCase(addUserResult.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      })
      .addMatcher(isFulfilledAction, () => {});
  },
});

// this is for configureStore
export default addUserResultSlice.reducer;
