import _ from "lodash";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Chip,
  colors,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(colors.common.white),
  backgroundColor: colors.common.white,

  "&:hover": {
    backgroundColor: colors.common.white,
  },
}));

const FilterMenu = ({
  defaultValue,
  isMobile,
  label,
  onApply,
  onChange,
  options,
  values,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!_.isEmpty(defaultValue)) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleToggle = (value) => {
    if (_.includes(values, value)) {
      onChange(_.without(values, value));
    } else {
      onChange([...values, value]);
    }
  };

  const MenuComponent = isMobile ? React.Fragment : Menu;
  const MenuComponentProps = isMobile
    ? {}
    : {
        anchorEl,
        onClose: () => {
          onChange(defaultValue);
          setAnchorEl(null);
        },
        open: Boolean(anchorEl),
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" },
        sx: { mt: 1 },
      };

  return (
    <>
      {isMobile ? (
        <Typography variant="h6">{label}</Typography>
      ) : (
        <StyledButton
          variant="contained"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<ArrowDropDownIcon />}
        >
          {!_.isEmpty(defaultValue) && (
            <Chip
              color="primary"
              label={_.size(defaultValue)}
              size="small"
              sx={{ mr: 1 }}
            />
          )}
          {label}
        </StyledButton>
      )}
      <MenuComponent {...MenuComponentProps}>
        <List sx={isMobile ? {} : { width: 280 }}>
          {_.map(options, ({ title, subTitle, value }) => (
            <ListItem
              secondaryAction={
                <Checkbox
                  checked={_.includes(values, value)}
                  edge="end"
                  onChange={() => handleToggle(value)}
                />
              }
              key={value}
              disablePadding
            >
              <ListItemButton onClick={() => handleToggle(value)}>
                <ListItemText primary={title} secondary={subTitle} />
              </ListItemButton>
            </ListItem>
          ))}
          {!isMobile && (
            <>
              <Divider />
              <ListItem sx={{ justifyContent: "end" }}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    disabled={_.isEmpty(values)}
                    onClick={() => onChange([])}
                    variant="text"
                  >
                    Clear
                  </Button>
                  <Button
                    color="error"
                    disabled={_.isEqual(
                      _.sortBy(values),
                      _.sortBy(defaultValue)
                    )}
                    onClick={() => {
                      onApply(values);
                      setAnchorEl(null);
                    }}
                    variant="text"
                  >
                    Apply
                  </Button>
                </Stack>
              </ListItem>
            </>
          )}
        </List>
      </MenuComponent>
    </>
  );
};

FilterMenu.propTypes = {
  defaultValue: PropTypes.array,
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  values: PropTypes.array,
};

FilterMenu.defaultProps = {
  defaultValue: [],
  isMobile: false,
};

export default FilterMenu;
