import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = {
  token: "",
  user_id: "",
};

export const doLogin = createAsyncThunk("auth/login", async (payload) => {
  let response;
  if (payload.type && payload.type == 'facebook') {
    response = await post(`${CONFIG.API_SERVER}/fbLoginOrRegister`, payload);
  } else {
    response = await post(`${CONFIG.API_SERVER}/login`, payload);
  }
  return response;
});

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled");
}

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(doLogin.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        return {
          ...state,
          status: "succeeded",
          user_id: action.payload.user_id,
          error: null,
        };
      })
      .addCase(doLogin.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      })
      .addMatcher(isFulfilledAction, () => {});
  },
});

// this is for configureStore
export default loginSlice.reducer;
