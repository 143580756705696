import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllEvents,
  selectCurrentRaceId,
  selectSessionFromCookies,
  selectShowRaceCard,
} from "../../selectors";

import CardHeader from "./components/cardHeader";
import RaceDistanceInfo from "./components/raceDistanceInfo";
import Tabs from "../Tabs";
import ReviewTab from "./components/ReviewTab";
import { getEventComments } from "../../redux-slice/async-slice/getEventComments";

const RaceCard = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(selectSessionFromCookies);
  const shouldShow = useSelector(selectShowRaceCard);
  const currentRaceId = useSelector(selectCurrentRaceId);
  const allRaces = useSelector(selectAllEvents);
  const [currentRace, setCurrentRace] = useState(null);


  useEffect(() => {
    if (!currentRaceId) {
      return;
    }

    const currentRace = allRaces.find(
      (race) => race.event_id === currentRaceId
    );

    setCurrentRace(currentRace);

    dispatch(
      getEventComments({
        token,
        event_id: currentRace.event_id,
      })
    );
  }, [currentRaceId]);


  const tabs = [
    {
      title: "Reviews",
      content: <ReviewTab />,
    },
    {
      title: "Description",
      content: <p className="race-description">{currentRace?.description}</p>,
    },
  ];

  return (
    shouldShow &&
    currentRace && (
      <div className="race-card">
        <CardHeader raceInfo={currentRace}/>
        <div className="race-card-bottom-side">
          <div className="button-container">
            <button onClick={()=>window.open(currentRace.home_page_url,'_blank')} className="enduric-button red">Visit website</button>
            {/* <button className="enduric-button white">book hotel now</button> */}
          </div>
          <RaceDistanceInfo raceInfo={currentRace} />
          <div className='tabs-component'>
            <Tabs tabs={tabs}/>
          </div>
        </div>
      </div>
    )
  );
};

export default RaceCard;
