import { createSlice } from "@reduxjs/toolkit";

const eventsFiltering = createSlice({
  name: "setEventsFiltering",
  initialState: {
    name: undefined,
    location: undefined,
    dates: [],
    continent: [],
    distance: [],
    series: [],
    rate: undefined,
  },
  reducers: {
    setEventsFilterRules: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setEventsFilterRules } = eventsFiltering.actions;
// this is for configureStore
export default eventsFiltering.reducer;
