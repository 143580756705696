import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = {
  token: "",
  user_id: "",
};

export const doRegistration = createAsyncThunk("auth/register", async (payload) => {
  const response = await post(`${CONFIG.API_SERVER}/register`, payload);
  return response;
});

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled");
}

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(doRegistration.fulfilled, (state, action) => {
        return {
          ...state,
          status: "succeeded",
          user_id: action.payload.user_id,
          error: null,
        };
      })
      .addCase(doRegistration.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      })
      .addMatcher(isFulfilledAction, () => {});
  },
});

// this is for configureStore
export default registrationSlice.reducer;
