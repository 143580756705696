import React from "react";
import { useDispatch } from "react-redux";
import { closeIcon } from "../../Icons";
import {
  setCurrentRace,
  setShowRaceCard,
} from "../../../redux-slice/appStateSlice";
import RaceStatus from "../../../components/RaceStatus"
import UpcomingToggle from "./UpcomingToggle"

const CardHeader = ({ raceInfo }) => {
  const { logo_url, title, series, country, city, event_start_date, event_id } = raceInfo;
  const dispatch = useDispatch();

  const closeRaceCard = () => {
    dispatch(setShowRaceCard({ showRaceCard: false }));
    dispatch(setCurrentRace({ currentRaceId: null }));
  };


  return (
    <div
      className="race-card-header"
      style={{ backgroundImage: `url(${logo_url})` }}
    >
      <div
        className="close-button"
        role="button"
        tabIndex={0}
        onKeyPress={closeRaceCard}
        onClick={closeRaceCard}
      >
        {closeIcon}
      </div>
      <div className="left-side">
        <div className="base-info">
          <h3 className="race-name" title={title}>
            {title}
          </h3>
          <div className="race-location">
            {new Date(event_start_date).toDateString()} • {country}, {city}
          </div>
          <div className="race-type">{series}</div>
        </div>
      </div>
      <div className="right-side">
        <RaceStatus raceDateStart={event_start_date} />
        {/*<div className="race-cost">$300</div>*/}
        {/*<div className="race-rate">4.6</div>*/}
        <UpcomingToggle event_id={event_id}/>
      </div>
    </div>
  );
};

export default CardHeader;
