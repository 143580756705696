import React, { useEffect, useState } from "react";
import UserInfo from "./components/userInfo";
import UpcomingSection from "./components/upcomingSection";
import ResultSection from "./components/resultSection";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";
import { getUserResults } from "../../redux-slice/async-slice/getUserResults";
import { useDispatch } from "react-redux";
import { getUpcomingEvents } from "../../redux-slice/async-slice/getUpcomingEvents";
import { useLocation } from 'react-router-dom';

const PublicProfile = () => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  let location = useLocation();

  const [patch, nickName] = location.pathname.split("/").filter(item => item !== "");
  const fetchData = async () => {
    const response = await get(`${CONFIG.API_SERVER}/getProfileDetailsByNickName`, null,{ nickName: nickName });

    await dispatch(getUserResults({ userId: response.user.user_id }));
    await dispatch(getUpcomingEvents({ userId: response.user.user_id }));
    setUserInfo(response.user);
  }

  useEffect( () => {
    const result = fetchData();
  }, []);

  return (
    <div className="profile-page public-page">
      <div className="images-container">
        <img
          src="https://static01.nyt.com/images/2020/05/01/sports/28virus-ironman1-print/28virus-ironman01-mediumSquareAt3X-v2.jpg"
          alt=""
        />
        <img
          src="https://media.cnn.com/api/v1/images/stellar/prod/120912123502-chrissie-wellington-race-day.jpg?q=w_3000,h_2001,x_0,y_0,c_fill"
          alt=""
        />
        <img
          src="https://www.chattanoogapulse.com/downloads/23954/download/IRONMAN%201.png?cb=80ed38ea688822b644aea7e0cfc4ba75"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/simpleview/image/upload/v1630343685/clients/chattanooga/ironman_5349386c-3d04-46b4-8363-84511f7a9184.jpg"
          alt=""
        />
        <img
          src="https://www.imsa.com/wp-content/uploads/sites/32/2022/11/10/JT-Ironman-Biking_11102022.jpg"
          alt=""
        />
        <img
          src="https://img.redbull.com/images/c_crop,w_5472,h_2736,x_0,y_912,f_auto,q_auto/c_scale,w_1200/redbullcom/2018/07/05/9c1fa29f-1fcd-45ac-8ede-20b2ed2b33d8/ironman-collection"
          alt=""
        />
        <img
          src="https://images.immediate.co.uk/production/volatile/sites/34/2022/10/brian-fogerty-ff973e0.jpg?quality=90&resize=768%2C574"
          alt=""
        />
        <img
          src="https://racing.trekbikes.com/wp-content/uploads/2021/04/APR11192-scaled.jpeg"
          alt=""
        />
      </div>
      <div className="profile-info-container">
        <UserInfo userInfo={userInfo} isPublicProfile={true}/>
        <section className="races-info">
          <UpcomingSection isPublicProfile={true}/>
          <ResultSection showAddResult={false} isPublicProfile={true}/>
        </section>
      </div>
    </div>
  );
}

export default PublicProfile;
