import React from "react";

const GradesCircle = ({ label, value }) => {
  return (
    <div className="grades-circle-container">
      <div
        aria-label="Progress"
        className="grades-circle"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="5"
        style={{ "--value": value }}
      />
      <span className="label">{label}</span>
    </div>
  );
};

export default GradesCircle;
