import _ from "lodash";
import find from "lodash/find";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Chip } from "@mui/material";
import RacesList from "../../components/races-list/RacesList";
import RacesMap from "./componenets/map-section/racesMap";
import {
  selectEventsFilteringList,
  selectSessionFromCookies,
  selectUserId,
  selectEventsFilters,
} from "../../selectors";
import { getEvents } from "../../redux-slice/async-slice/getEventsSlice";
import RaceCard from "../../components/RaceCard/RaceCard";
import SearchComponent from "./componenets/search-component";
import FilterBox from "./componenets/filter-box/FilterBox";
import { getUserId } from "../../redux-slice/async-slice/getUserId";
import {
  setCurrentRace,
  setShowRaceCard,
} from "../../redux-slice/appStateSlice";

const PAGE_TABS = {
  LIST: "list",
  MAP: "map",
  FILTER: "filter",
};

function RenderMobilePageTabe({ activeTab, updateActiveTab }) {
  switch (activeTab) {
    case PAGE_TABS.LIST:
      return <RacesList />;
    case PAGE_TABS.MAP:
      return <RacesMap />;
    case PAGE_TABS.FILTER:
      return (
        <FilterBox
          onApplyCallback={() => {
            updateActiveTab(PAGE_TABS.LIST);
            window.scrollTo(0, 0);
          }}
          isMobile
        />
      );
    default:
      return <RacesList />;
  }
}

function MainPage() {
  const dispatch = useDispatch();
  const { token } = useSelector(selectSessionFromCookies);
  const userID = useSelector(selectUserId);
  const events = useSelector(selectEventsFilteringList);
  const rules = useSelector(selectEventsFilters);
  const [activeTab, updateActiveTab] = useState(PAGE_TABS.LIST);
  const isMobile = window.matchMedia("(pointer:coarse)").matches;
  let location = useLocation();

  const activeRulesSize = _.size(
    _.chain(rules)
      .omitBy((rule) => _.isNil(rule) || _.isEmpty(rule))
      .map((value) => value)
      .flatten()
      .compact()
      .value()
  );

  const findEventByUrl = (events, eventInfo) => {
    return find(
      events,
      (event) =>
        event.title.toLowerCase().replace(/-/g, " ") ===
          eventInfo.title.toLowerCase() &&
        event.series.toLowerCase() === eventInfo.series.toLowerCase() &&
        new Date(event.event_start_date).getFullYear() == eventInfo.year
    );
  };

  const showEventByURL = (eventId) => {
    dispatch(setCurrentRace({ currentRaceId: eventId }));
    dispatch(setShowRaceCard({ showRaceCard: true }));
  };

  useEffect(() => {
    if (!userID) {
      dispatch(getUserId(token));
    }
    dispatch(getEvents(token));
  }, []);

  useEffect(() => {
    const [race, brand, year, eventTitle] = location.pathname
      .split("/")
      .filter((item) => item !== "");

    if (events.length === 0 || race === undefined) return;

    const eventFromURL = findEventByUrl(events, {
      title: eventTitle.replace(/-/g, " "),
      series: brand.replace(/-/g, " "),
      year,
    });

    if (!!eventFromURL) {
      showEventByURL(eventFromURL.event_id);
    }
  }, [events]);

  return (
    <div id="main-page">
      <div className="search-box-mobile-view show-if-mobile">
        <SearchComponent />
        <div className="view-switch-button">
          <button
            className={`enduric-toggle-button white ${
              activeTab === PAGE_TABS.MAP && "active"
            }`}
            onClick={() => updateActiveTab(PAGE_TABS.MAP)}
          >
            MAP
          </button>
          <button
            className={`enduric-toggle-button white ${
              activeTab === PAGE_TABS.LIST && "active"
            }`}
            onClick={() => updateActiveTab(PAGE_TABS.LIST)}
          >
            LIST
          </button>
          <Badge badgeContent={activeRulesSize} color="primary">
            <button
              className={`enduric-toggle-button white ${
                activeTab === PAGE_TABS.FILTER && "active"
              }`}
              onClick={() => updateActiveTab(PAGE_TABS.FILTER)}
            >
              FILTER
            </button>
          </Badge>
        </div>
        <RaceCard />
      </div>

      {isMobile && (
        <RenderMobilePageTabe
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
        />
      )}

      {!isMobile && (
        <>
          <RacesList />
          <RacesMap>
            <FilterBox />
          </RacesMap>
        </>
      )}
    </div>
  );
}

export default MainPage;
