import React from "react";
import { CONFIG } from "../../../../src/utils/api/const";
import { editIcon } from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../../../static/images/default-avatar.png";
import ageCalculator from "../../../utils/ageCalculator";

const UserInfo = ({ userInfo, isPublicProfile=false }) => {
  const navigate = useNavigate();
  const handleImgLoadingError = (e) => (e.target.src = defaultAvatar);

  const {
    first_name,
    last_name,
    club_name,
    country,
    city,
    gender,
    weight,
    height,
    avatar,
    dob
  } = userInfo;
  return (
    <div className="user-info">
      <section className="name-container">
        <img
          src={CONFIG.AVATARS_AWS_S3 + avatar}
          onError={(e) => handleImgLoadingError(e)}
          alt={first_name + ' ' + last_name}
          className="photo"
        />
        <div className="name-info">
          {isPublicProfile && (
            <p className="name">
              {first_name} {last_name}
            </p>
          )}
          {!isPublicProfile && (
            <p className="name" onClick={() => navigate('/profile-edit')}>
              {first_name} {last_name} <i className="edit-button">{editIcon}</i>
            </p>
          )}
          <p className="club-name">{club_name}</p>
          <p className="location">
            {country} {city}
          </p>
        </div>
      </section>
      <section className="biography-container">
        <h3 className="section-title">Biography</h3>
        <div className="flex-container">
          <div className="left">
            <p className="section-item">
              Gender: <span className="value">{gender}</span>
            </p>
            <p className="section-item">
              Age: <span className="value">{ageCalculator(dob)}</span>
            </p>
          </div>
          <div className="right">
            <p className="section-item">
              Height: <span className="value">{height}</span>
            </p>
            <p className="section-item">
              Weight: <span className="value">{weight}</span>
            </p>
          </div>
        </div>
      </section>
      {/*
      <section className="statistics-container">
        <div className="left">
          <h3 className="section-title">Statistics</h3>
          <p className="section-item">Long distance:</p>
          <p className="section-item">Middle distance:</p>
          <p className="section-item">Olympic:</p>
        </div>
        <div className="right">
          <h3 className="section-title">Total: 20</h3>
          <p className="section-item">
            <span className="regularity">3 x </span>
            <span className="value">Long Distance, PB 9:58</span>
          </p>
          <p className="section-item">
            <span className="regularity">7 x </span>
            <span className="value">Middle Distance, PB 4:31</span>
          </p>
          <p className="section-item">
            <span className="regularity">10 x </span>
            <span className="value">Olympic, PB 2:19</span>
          </p>
        </div>
      </section>
      */}
    </div>
  );
};

export default UserInfo;
