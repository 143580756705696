import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = [];

export const getEvents = createAsyncThunk("getAllEvents", async (token) => {
  const response = await get(`${CONFIG.API_SERVER}/events`, token);
  return response;
});

const toAddShareURLToEvent = (data) => {
  return data.map((event) => {
    const brand = event.series.toLowerCase().replace(/ /g, "-");
    const title = event.title.toLowerCase().replace(/ /g, "-");
    const year = new Date(event.event_start_date).getFullYear();
    return {
      ...event,
      shareUrl: `${"/race/" + brand + "/" + year + "/" + title}`,
    };
  });
};

const getEventsSlice = createSlice({
  name: "getEvents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEvents.fulfilled, (state, action) => {
        return toAddShareURLToEvent(action.payload);
      })
      .addCase(getEvents.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

export const { setEventsFilterRules } = getEventsSlice.actions;
// this is for configureStore
export default getEventsSlice.reducer;
