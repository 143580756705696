import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker as GoogleMarker,
  MarkerClusterer,
} from "@react-google-maps/api";

import RaceCard from "../../../../components/RaceCard/RaceCard";
import { useDispatch, useSelector } from "react-redux";
import { selectEventsFilteringList } from "../../../../selectors";
import {
  setCurrentRace,
  setShowRaceCard,
} from "../../../../redux-slice/appStateSlice";

const DEFAULT_LOCATION = { lat: 46.6983709, lng: 9.0575133 };

const RacesMap = ({ children }) => {
  const dispatch = useDispatch();

  const events = useSelector(selectEventsFilteringList);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const options = {
    disableDefaultUI: true,
    mapTypeId: "roadmap",
    labels: true,
    center: true,
    fullscreenControl: true,
  };

  const Marker = ({ position, clusterer, event_id }) => {
    const showRaceCard = () => {
      dispatch(setCurrentRace({ currentRaceId: event_id }));
      dispatch(setShowRaceCard({ showRaceCard: true }));
    };

    return (
      <GoogleMarker
        onClick={showRaceCard}
        position={position}
        clusterer={clusterer}
      />
    );
  };

  return (
    <div className="map-container">
      <div className="hide-if-mobile">
        <RaceCard />
      </div>
      {children}
      <div id="map">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            options={options}
            center={DEFAULT_LOCATION}
            zoom={4}
          >
            <MarkerClusterer
              options={{ maxZoom: 20 }}
              averageCenter
              enableRetinaIcons
              gridSize={60}
            >
              {(cluster) =>
                events.length > 0 &&
                events.map((event) => (
                  <Marker
                    key={event.event_id}
                    event_id={event.event_id}
                    position={{
                      lat:
                        parseFloat(event.latitude) +
                        (Math.floor(Math.random() * 20) + 25) * 0.0001,
                      lng:
                        parseFloat(event.longitude) +
                        (Math.floor(Math.random() * 20) + 25) * 0.0001,
                    }}
                    clusterer={cluster}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default RacesMap;
