import React from "react";
import CommentItem from "./CommentItem";
import { useSelector } from "react-redux";
import { selectEventComments } from "../../selectors";
import defaultLogo from "../../static/images/default-event-logo.jpg";

const CommentsSection = () => {
  const comments = useSelector(selectEventComments);
  const isCommentsExist = Array.isArray(comments) && comments.length > 0;
  const handleImgLoadingError = (e) => (e.target.src = defaultLogo);

  return (
    isCommentsExist &&
    comments.map((comment) => (
      <div className="comment-section">
        <div className="user-info-section">
          <img
            onError={(e) => handleImgLoadingError(e)}
            src={
              "https://heroku-enduric-images.s3.eu-central-1.amazonaws.com/avatars/" +
              comment.avatar
            }
            alt="User Avatar"
          />
          <div className="user-info">
            <div className="user-name">
              {comment.first_name} {comment.last_name}
            </div>
            <div className="overall-time">
              Overall time: {comment.overall_time}
            </div>
            <div className="comment-date">{comment.event_review_date}</div>
          </div>
        </div>
        <div className="comment">
          <CommentItem commentData={comment} />
        </div>
      </div>
    ))
  );
};

export default CommentsSection;
