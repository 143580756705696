import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from 'react-facebook-login';

import { doLogin } from "../../../redux-slice/async-slice/loginSlice";
import { selectAccount } from "../../../selectors";
import { setSessionToken } from "../../../redux-slice/sessionSlice";
import getCookie from "../../../utils/getCookies";
import { Navigate, useNavigate } from "react-router-dom";
import { bigLogo } from "../../../components/Icons";
import { setGuestUser } from "../../../redux-slice/appStateSlice";
import EnduricInput from "../../../components/EnduricInput";

const LoginPage = () => {
  let [formData, setFormData] = useState({  email: "", password: "" });

  const dispatch = useDispatch();
  const accountInfo = useSelector(selectAccount);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const loginRequest = (event) => {
    event.preventDefault();
    dispatch(doLogin({
      email: formData.email,
      password: formData.password,
    }));
  };

  const loginAsGuest = () => {
    dispatch(setGuestUser({ guestUser: true }));
    navigate("/");
  };

  // const [login, setLogin] = useState(false);
  // const [data, setData] = useState({});
  // const [picture, setPicture] = useState('');


  const responseFacebook = (response) => {
    console.log(response);
    const {email, first_name, last_name, picture, id} = response;
    const fbLogin = {email, first_name, last_name,picture: picture.data.url, id, type:'facebook'};
    dispatch(doLogin(fbLogin));    
    // setData(response);
    // setPicture(response.picture.data.url);
    // if (response.accessToken) {
    //   setLogin(true);
    // } else {
    //   setLogin(false);
    // }
  }


  const goToRegistration = () => {
    navigate("/registration");
  };

  useEffect(() => {
    const token = getCookie("token");
    dispatch(setSessionToken({ token }));
  }, [accountInfo]);

  return (
    <div className="auth-login-page">
      {accountInfo.user_id && <Navigate to="/" replace />}
      <div className="login-component">
        <div className="logo-box">
          {bigLogo}
          <p className="logo-box-tagline">Welcome back to Enduric World</p>
        </div>
        <form onSubmit={loginRequest}>
          <fieldset>
            <legend>Log In</legend>
            <EnduricInput
              name={"email"}
              value={formData.email}
              label={"Email"}
              id={"email"}
              ariaLabel={"Email"}
              type={"email"}
              placeholder={"Enter your email"}
              onChange={handleChange}
              errorMessage={"Please type correct email."}
            />

            <EnduricInput
              name={"password"}
              value={formData.password}
              label={"Password"}
              id={"password"}
              ariaLabel={"Password"}
              type={"password"}
              placeholder={"Enter your password"}
              onChange={handleChange}
              errorMessage={"Please type correct password."}
            />
            <button className="enduric-button red submit">Sign In</button>
          </fieldset>
        </form>
        <button
          className="enduric-button white guest-login"
          onClick={goToRegistration}
        >
          Sign Up
        </button>
        <button
          className="enduric-button white guest-login"
          onClick={loginAsGuest}
        >
          Login as a guest
        </button>
        
        <div className="enduric-button" >
        <FacebookLogin
            appId="925037197942655"
            autoLoad={false}
            fields="id,name,email,location,age_range,about,gender,hometown,sports,picture.height(500),birthday,first_name,last_name"
            scope="public_profile"
            callback={responseFacebook}
            icon="fa-facebook"
            size="small"
          />
          </div>
        
      </div>
    </div>
  );
};

export default LoginPage;
