import axios from 'axios';

export const get = async function (endpoint, token, params) {
  const queryParams = new URLSearchParams(params).toString();
  const response = await axios.get(`${endpoint + "?" + queryParams}`, { headers: { Authorization: `Bearer ${token}` } });

  if (!response.status || response.status !== 200) {
    const error = await response.data;
    throw new Error(error.error);
  } else {
    return response.data;
  }
};

export const post = async function (endpoint, data, isFormData = false, token) {
  const response = await axios.post(endpoint, data, { headers: { "Content-Type": isFormData ? `multipart/form-data` : "application/json" , Authorization: `Bearer ${token}` }, withCredentials: true });

  if (!response.status || response.status !== 200) {
    const error = await response.data;
    throw new Error(error.error);
  } else {
    return response.data;
  }
};
