import { React } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LoginPage from "../pages/auth/login";
import ProfilePage from "../pages/profile";
import PublicProfile from "../pages/profile/PublicProfile";
import EditProfilePage from "../pages/edit-profile";
import MainPage from "../pages/main";
import ErrorPage from "../pages/error-page/ErrorPage";
import ProtectedRoute from "./ProtectedRoute";
import RedirectPage from "../pages/redirect/RedirectPage";
import RegistrationPage from "../pages/auth/registration";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      // element: <ProtectedRoute canBeGuest={isGuestUser} redirectPath="/login"><MainPage /></ProtectedRoute>,
      element: <MainPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "race/:brand/:year/:eventTitle",
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/registration",
      element: <RegistrationPage />,
    },
    {
      path: "profile",
      element: (
        <ProtectedRoute redirectPath="/login">
          <ProfilePage />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "athlete/:nickname",
      element: <PublicProfile />,
      errorElement: <ErrorPage />,
    },
    {
      path: "profile-edit",
      element: (
        <ProtectedRoute redirectPath="/redirect-page">
          <EditProfilePage />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "redirect-page",
      element: <RedirectPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routing;
