import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = [];

export const getUpcomingEvents = createAsyncThunk("getUpcomingEvents", async ({ userId }) => {
  const response = await get(`${CONFIG.API_SERVER}/getUserUpcomingEvents`, null, {userId: userId});
  return response;
});

const getUpcomingEventsSlice = createSlice({
  name: "getUpcomingEvents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUpcomingEvents.fulfilled, (state, action) => {
        return action.payload.userUpcomingEvents;
      })
      .addCase(getUpcomingEvents.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

export default getUpcomingEventsSlice.reducer;
