import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectNewAccount } from "../../../selectors";
import { setSessionToken } from "../../../redux-slice/sessionSlice";
import getCookie from "../../../utils/getCookies";
import { bigLogo } from "../../../components/Icons";
import { doRegistration } from "../../../redux-slice/async-slice/RegistrationSlice";
import { Navigate } from "react-router-dom";
import EnduricInput from "../../../components/EnduricInput";

const RegistrationPage = () => {
  let [formData, setFormData] = useState({
    email: "",
    password: "",
    password2: "",
    first_name: "",
    last_name: ""
  });


  const dispatch = useDispatch();
  const accountInfo = useSelector(selectNewAccount);


  const registrationRequest = (event) => {
    event.preventDefault();

    dispatch(
      doRegistration({
        email: formData.email,
        password: formData.password,
        password2: formData.password2,
        first_name: formData.first_name,
        last_name: formData.last_name,
        city: " ",
        country: " ",
      })
    );
  };

  const isError = (action) => {
    return action() ? "success" : "error";
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {

    } else {
      dispatch(setSessionToken({ token }));
    }
  }, [accountInfo]);

  return (
    <div className="auth-login-page">
      {accountInfo.user_id && <Navigate to="/" replace />}
      <div className="login-component">
        <div className="logo-box">
          {bigLogo}
          <p className="logo-box-tagline">Welcome back to Enduric World</p>
        </div>

        <form onSubmit={registrationRequest}>
          <fieldset>
            <legend>Sing Up</legend>

            <EnduricInput
              name={"first_name"}
              value={formData.first_name}
              label={"First Name"}
              placeholder={"Enter your first name"}
              ariaLabel={"First Name"}
              errorMessage={"Please enter a valid First Name"}
              id={"firstName"}
              type={"text"}
              onChange={handleChange}
            />

            <EnduricInput
              name={"last_name"}
              value={formData.last_name}
              label={"Last Name"}
              placeholder={"Enter your last name"}
              ariaLabel={"Last Name"}
              errorMessage={"Please enter a valid Last Name"}
              id={"lastName"}
              type={"text"}
              onChange={handleChange}
            />

            <EnduricInput
              name={"email"}
              value={formData.email}
              label={"Email"}
              placeholder={"Enter your email"}
              ariaLabel={"Email"}
              errorMessage={"Please enter a valid email address"}
              id={"email"}
              type={"email"}
              onChange={handleChange}
            />

            <EnduricInput
              name={"password"}
              value={formData.password}
              label={"Password"}
              placeholder={"Enter your password"}
              ariaLabel={"Password"}
              errorMessage={"Error message!"}
              id={"password"}
              type={"password"}
              onChange={handleChange}
            />

            <EnduricInput
              name={"password2"}
              value={formData.password2}
              label={"Retry Password"}
              placeholder={"Enter your password one more time"}
              ariaLabel={"Password"}
              errorMessage={"Error message!"}
              id={"password2"}
              type={"password"}
              onChange={handleChange}
            />

            <button className="enduric-button red submit">Sing up</button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default RegistrationPage;
