import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Button, Divider, ListItem, Stack, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { continents } from "countries-list";
import { selectAllEvents, selectEventsFilters } from "../../../../selectors";
import { setEventsFilterRules } from "../../../../redux-slice/eventsFilterRulesSlice";
import {
  EVENT_SERIES,
  EVENT_TYPE,
  EVENT_TYPE_DISTANCE,
} from "../../../../consts";
import { useFilterBox, FilterBoxProvider } from "./filter-box.provider";
import FilterMenu from "./filter-menu";
import FilterDates from "./filter-dates";

const EXCLUDED_CONTINENTS = ["AN"];

const StyledStack = styled(Stack)(({ theme }) => ({
  "&": {
    position: "absolute",
    zIndex: theme.zIndex.appBar,
  },
}));

const FilterBoxWrappet = ({ isMobile, onApplyCallback }) => {
  const {
    state,
    updateDates,
    updateContinent,
    updateDistance,
    updateSeries,
    resetState,
  } = useFilterBox();

  const dispatch = useDispatch();
  const rules = useSelector(selectEventsFilters);
  const events = useSelector(selectAllEvents);

  const setFilter = (filterName, filterValue) => {
    dispatch(setEventsFilterRules({ [filterName]: filterValue }));
  };

  const StackComponent = isMobile ? Stack : StyledStack;

  return (
    !_.isEmpty(events) && (
      <FilterBoxProvider>
        <StackComponent
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ p: 2 }}
        >
          <FilterDates
            dates={_.map(events, "event_start_date")}
            defaultValue={rules.dates}
            isMobile={isMobile}
            label="Dates"
            onApply={(filterValue) => setFilter("dates", filterValue)}
            onChange={updateDates}
            values={state.dates}
          />
          <FilterMenu
            defaultValue={rules.continent}
            isMobile={isMobile}
            label="Continent"
            onApply={(filterValue) => setFilter("continent", filterValue)}
            onChange={updateContinent}
            options={_.chain(continents)
              .omit(EXCLUDED_CONTINENTS)
              .map((title, value) => ({ title, value }))
              .value()}
            values={state.continent}
          />
          <FilterMenu
            defaultValue={rules.distance}
            isMobile={isMobile}
            label="Distance"
            onApply={(filterValue) => setFilter("distance", filterValue)}
            onChange={updateDistance}
            options={_.map(EVENT_TYPE, (title, value) => ({
              title,
              subTitle: _.chain(EVENT_TYPE_DISTANCE[value])
                .values()
                .map((value) => value.slice(0, -2))
                .join(" - ")
                .value(),
              value,
            }))}
            values={state.distance}
          />
          <FilterMenu
            defaultValue={rules.series}
            isMobile={isMobile}
            label="Series"
            onApply={(filterValue) => setFilter("series", filterValue)}
            onChange={updateSeries}
            options={_.map(EVENT_SERIES, (title, value) => ({
              title,
              value,
            }))}
            values={state.series}
          />
          {isMobile && (
            <>
              <Divider />
              <ListItem as="div" sx={{ justifyContent: "end" }}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    disabled={_.isEmpty(_.omitBy(state, _.isEmpty))}
                    onClick={resetState}
                    variant="text"
                  >
                    Clear
                  </Button>
                  <Button
                    color="error"
                    disabled={_.isEqual(
                      _.omitBy(state, _.isEmpty),
                      _.omitBy(
                        rules,
                        (rule) => _.isNil(rule) || _.isEmpty(rule)
                      )
                    )}
                    onClick={() => {
                      dispatch(setEventsFilterRules(state));

                      if (onApplyCallback) {
                        onApplyCallback();
                      }
                    }}
                    variant="text"
                  >
                    Apply
                  </Button>
                </Stack>
              </ListItem>
            </>
          )}
        </StackComponent>
      </FilterBoxProvider>
    )
  );
};

const FilterBox = (props) => {
  return (
    <FilterBoxProvider>
      <FilterBoxWrappet {...props} />
    </FilterBoxProvider>
  );
};

FilterBox.propTypes = {
  isMobile: PropTypes.bool,
  onApplyCallback: PropTypes.func,
};

FilterBox.defaultProps = {
  isMobile: false,
};

export default FilterBox;
