import { createContext, useContext, useMemo, useReducer } from "react";

const INITIAL_STATE = {
  dates: [],
  continent: [],
  distance: [],
  series: [],
};

const ACTION_TYPES = {
  UPDATE_DATES: "UPDATE_DATES",
  UPDATE_CONTINENT: "UPDATE_CONTINENT",
  UPDATE_DISTANCE: "UPDATE_DISTANCE",
  UPDATE_SERIES: "UPDATE_SERIES",
  RESET_STATE: "RESET_STATE",
};

export const FilterBoxContext = createContext({
  state: INITIAL_STATE,
  updateDates: () => {},
  updateContinent: () => {},
  updateDistance: () => {},
  updateSeries: () => {},
  resetState: () => {},
});

export const useFilterBox = () => useContext(FilterBoxContext);

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.UPDATE_DATES:
      return { ...state, dates: payload };
    case ACTION_TYPES.UPDATE_CONTINENT:
      return { ...state, continent: payload };
    case ACTION_TYPES.UPDATE_DISTANCE:
      return { ...state, distance: payload };
    case ACTION_TYPES.UPDATE_SERIES:
      return { ...state, series: payload };
    case ACTION_TYPES.RESET_STATE:
      return INITIAL_STATE;
    default:
      throw new Error("Unuseble action");
  }
}

export function FilterBoxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <FilterBoxContext.Provider
      value={useMemo(
        () => ({
          state,
          updateDates: (payload) =>
            dispatch({ type: ACTION_TYPES.UPDATE_DATES, payload }),
          updateContinent: (payload) =>
            dispatch({ type: ACTION_TYPES.UPDATE_CONTINENT, payload }),
          updateDistance: (payload) =>
            dispatch({ type: ACTION_TYPES.UPDATE_DISTANCE, payload }),
          updateSeries: (payload) =>
            dispatch({ type: ACTION_TYPES.UPDATE_SERIES, payload }),
          resetState: () => dispatch({ type: ACTION_TYPES.RESET_STATE }),
        }),
        [state, dispatch]
      )}
    >
      {children}
    </FilterBoxContext.Provider>
  );
}
