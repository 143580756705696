import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  colors,
  Divider,
  List,
  ListItem,
  Menu,
  Slider,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(colors.common.white),
  backgroundColor: colors.common.white,

  "&:hover": {
    backgroundColor: colors.common.white,
  },
}));

function getDateRange(firstDate, lastDate) {
  if (firstDate.isSame(lastDate, "month")) {
    return [lastDate.format("MMM YYYY")];
  }

  let date = firstDate;

  const dates = [date.format("MMM YYYY")];

  do {
    date = date.add(1, "month");
    dates.push(date.format("MMM YYYY"));
  } while (date.isBefore(lastDate));

  return dates;
}

const FilterDates = ({
  dates,
  defaultValue,
  isMobile,
  label,
  onApply,
  onChange,
  values,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const moments = _.map(dates, (date) => moment(date));
  const [minDate, maxDate] = [moment.min(moments), moment.max(moments)];
  const dateRange = getDateRange(minDate, maxDate);

  const initialRange = [dateRange[0], dateRange[_.size(dateRange) - 1]];

  useEffect(() => {
    if (!_.isEmpty(defaultValue)) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (__, [start, end]) =>
    onChange([dateRange[start - 1], dateRange[end - 1]]);

  const MenuComponent = isMobile ? React.Fragment : Menu;
  const MenuComponentProps = isMobile
    ? {}
    : {
        anchorEl,
        onClose: () => {
          onChange(_.isEmpty(defaultValue) ? initialRange : defaultValue);
          setAnchorEl(null);
        },
        open: Boolean(anchorEl),
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" },
        sx: { mt: 1 },
      };

  return (
    <>
      {isMobile ? (
        <Typography variant="h6">{label}</Typography>
      ) : (
        <StyledButton
          variant="contained"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<ArrowDropDownIcon />}
        >
          {!_.isEmpty(defaultValue) &&
            !_.isEqual(defaultValue, initialRange) && (
              <Chip
                color="primary"
                label={_.size(defaultValue)}
                size="small"
                sx={{ mr: 1 }}
              />
            )}
          {label}
        </StyledButton>
      )}
      <MenuComponent {...MenuComponentProps}>
        <List sx={isMobile ? {} : { width: 280 }}>
          <ListItem sx={{ p: 4 }}>
            <Box sx={{ flex: "0 0 100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  onClick={() => onChange([dateRange[0], values[1]])}
                  sx={{ cursor: "pointer" }}
                >
                  {dateRange[0]}
                </Typography>
                <Typography
                  variant="body2"
                  onClick={() =>
                    onChange([values[0], dateRange[_.size(dateRange) - 1]])
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {dateRange[_.size(dateRange) - 1]}
                </Typography>
              </Box>
              <Slider
                marks
                max={_.size(dateRange)}
                min={1}
                step={1}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => dateRange[value - 1]}
                value={
                  _.isEmpty(values)
                    ? [
                        _.indexOf(dateRange, initialRange[0]) + 1,
                        _.indexOf(dateRange, initialRange[1]) + 1,
                      ]
                    : [
                        _.indexOf(dateRange, values[0]) + 1,
                        _.indexOf(dateRange, values[1]) + 1,
                      ]
                }
                onChange={handleChange}
              />
            </Box>
          </ListItem>
          {!isMobile && (
            <>
              <Divider />
              <ListItem sx={{ justifyContent: "end" }}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    disabled={
                      _.isEmpty(values) || _.isEqual(values, initialRange)
                    }
                    onClick={() => onChange(initialRange)}
                    variant="text"
                  >
                    Clear
                  </Button>
                  <Button
                    color="error"
                    disabled={
                      _.isEmpty(values) ||
                      _.isEqual(
                        values,
                        _.isEmpty(defaultValue) ? initialRange : defaultValue
                      )
                    }
                    onClick={() => {
                      onApply(values);
                      setAnchorEl(null);
                    }}
                    variant="text"
                  >
                    Apply
                  </Button>
                </Stack>
              </ListItem>
            </>
          )}
        </List>
      </MenuComponent>
    </>
  );
};

FilterDates.propTypes = {
  dates: PropTypes.array,
  defaultValue: PropTypes.array,
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
};

FilterDates.defaultProps = {
  defaultValue: [],
  isMobile: false,
};

export default FilterDates;
