import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = [];

export const getUserResults = createAsyncThunk("getUserResults", async ({ token, userId }) => {
  const response = await get(`${CONFIG.API_SERVER}/getUserResults`, null, {userId: userId});
  return response;
});

const getUserResultsSlice = createSlice({
  name: "getUserResults",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserResults.fulfilled, (state, action) => {
        return action.payload.userResults;
      })
      .addCase(getUserResults.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

export default getUserResultsSlice.reducer;
