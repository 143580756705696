import { configureStore } from "@reduxjs/toolkit";
import appStateReducer from "./redux-slice/appStateSlice";
import loginReducer from "./redux-slice/async-slice/loginSlice";
import sessionReducer from "./redux-slice/sessionSlice";
import getEventsReducer from "./redux-slice/async-slice/getEventsSlice";
import getUserInfoReducer from "./redux-slice/async-slice/getUserInfo";
import eventsFiltering from "./redux-slice/eventsFilterRulesSlice";
// import setEventComments from './redux-slice/setEventsComments';
import getEventCommentsReducer from "./redux-slice/async-slice/getEventComments";
import getUserId from "./redux-slice/async-slice/getUserId";
import getUserResults from "./redux-slice/async-slice/getUserResults";
import getUpcomingEvents from "./redux-slice/async-slice/getUpcomingEvents";
import registrationReducer from "./redux-slice/async-slice/RegistrationSlice";
import updateProfileInfo from "./redux-slice/async-slice/updateProfileInfo";
import addUserResult from "./redux-slice/async-slice/addUserResult";

const store = configureStore({
  reducer: {
    appState: appStateReducer,
    userInfo: getUserInfoReducer,
    login: loginReducer,
    registration: registrationReducer,
    session: sessionReducer,
    allEvents: getEventsReducer,
    eventComments: getEventCommentsReducer,
    userResults: getUserResults,
    upcomingEvents: getUpcomingEvents,
    // setEventComments,
    eventsFiltering,
    addUserResult,
    updateProfileInfo,
    userId: getUserId,

  },
});

export default store;
