import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { selectEventsFilteringList } from "../../selectors";

import RaceListItem from "./RaceListItem";
import SearchComponent from "../../pages/main/componenets/search-component";
import { smallLogo } from "../Icons";

const RacesList = () => {
  const events = useSelector(selectEventsFilteringList);

  return (
    <div className="races-list-container">
      {!Array.isArray(events) && (
        <div className="loading-component">{smallLogo}</div>
      )}
      <div className="hide-if-mobile">
        <SearchComponent />
      </div>
      <div className="races-list-scroll-container">
        {_.isEmpty(events) ? (
          <Alert severity="info" sx={{ my: 1 }}>
            Events not found
          </Alert>
        ) : (
          _.map(events, (race) => (
            <RaceListItem key={race.event_id} raceInfo={race} />
          ))
        )}
      </div>
    </div>
  );
};

export default RacesList;
