import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = [];

export const getEventComments = createAsyncThunk(
  "getEventComments",
  async ({ token, ...event_id }) => {
    return await get(`${CONFIG.API_SERVER}/getEventComments`, token, event_id);
  }
);

const getEventCommentsSlice = createSlice({
  name: "getEventComments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEventComments.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getEventComments.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          [action.meta.arg.event_id]: action.payload.result,
        };
      })
      .addCase(getEventComments.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

// export const {setEventComments} = getEventCommentsSlice.actions;
// this is for configureStore
export default getEventCommentsSlice.reducer;
