import React from "react";
import ReviewsContainer from "../../Reviews/ReviewsContainer";
import CommentsSection from "../../Comments/CommentsSection";

const ReviewTab = () => {
  return (
    <>
      <ReviewsContainer />
      <CommentsSection />
    </>
  );
};

export default ReviewTab;
