import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div>
      <h1>Hi there, it looks like you have run to the wrong point.</h1>
      <br />
      <Link to="/">Let's from start :-)</Link>
    </div>
  );
};

export default ErrorPage;
