import React from "react";
import { addIcon, editIcon } from "../../../components/Icons";
import RaceListItem from "../../../components/races-list/RaceListItem";
import { useSelector } from "react-redux";
import { selectUpcomingEvents } from "../../../selectors";
import { Link } from "react-router-dom";

const UpcomingSection = ({isPublicProfile = false}) => {
  const upcomingEvents = useSelector(selectUpcomingEvents);

  return (
    <section className="upcoming-section">
      <div className="section-title-container">
        <h3>Upcoming</h3>
        {/*<h3>Upcoming <i className="edit-button">{editIcon}</i></h3>*/}
        {/*<div className="add-race-button">{addIcon} Add race</div>*/}
      </div>
      <div className="ucomming-list">
        {!upcomingEvents.length ? (
          <div className="no-events"> No Upcoming events, use  <Link to='/'>Enduric</Link> to find your next race. </div>
        ) : (
          upcomingEvents.map((event) => (
            <RaceListItem key={event.event_id} raceInfo={event} isPublicProfile={isPublicProfile}/>
          ))
        )}
      </div>
    </section>
  );
};

export default UpcomingSection;
