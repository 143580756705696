import React from "react";
import {
  addIcon,
  calendarRedIcon, editIcon,
  timerRedIcon
} from "../../../components/Icons";
import { useSelector } from "react-redux";
import { selectUserResults } from "../../../selectors";
import { EVENT_TYPE } from "../../../consts";
import { Link } from "react-router-dom";

const ResultItem = ({ result }) => {
  const {
    overall,
    event_date,
    event_country,
    event_city,
    event_type,
    event_title,
  } = result;
  return (
    <div className="result-item">
      <div className="result-info">
        <div className="name">{event_title}</div>
        <div className="time-and-type">
          <span className="time">
            <span>{timerRedIcon}</span> {overall}
          </span>
          <span className="type">{EVENT_TYPE[event_type]}</span>
        </div>
      </div>
      <div className="result-info">
        <div className="location-and-date">
          <div className="location">
            {event_country}, {event_city}
          </div>
          <div className="date">
            <span>{calendarRedIcon}</span> {event_date}
          </div>
        </div>
      </div>
      <div className="rating-info">
        <div className="rating">4.2</div>
        <div className="feedback-button">feedback</div>
      </div>
    </div>
  );
};
const ResultSection = ({showAddResult, isPublicProfile = false}) => {

  const userResults = useSelector(selectUserResults)

  return (
    <section className="results-section">
      <div className="section-title-container">
        <h3 className="title">Results {!isPublicProfile && <i className="edit-button">{editIcon}</i>}</h3>
        {!isPublicProfile && <div className="add-result-button" onClick={() => showAddResult(true)}>{addIcon} Add result</div>}
      </div>
      <div className="results-list">
        {
          userResults.length > 0 ? userResults.map(result => <ResultItem key={result.ur_id} result={result} />) :
          <div className="no-events"> No Results.</div>
        }
      </div>
    </section>
  )
};

export default ResultSection;
