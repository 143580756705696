import React from "react";

import GradesCircle from "./GradesCircle";
import { useSelector } from "react-redux";
import { selectEventComments } from "../../selectors";

const ReviewsContainer = () => {
  const comments = useSelector(selectEventComments);

  function calculateRating() {
    const ratingTypes = [
      {
        valueName: "swim_course_rate",
        label: "Swim Course",
      },
      {
        valueName: "run_course_rate",
        label: "Run Course",
      },
      {
        valueName: "bike_course_rate",
        label: "Bike Course",
      },
    ];

    function calculate(rating) {
      const arrayRating = comments.map((item) => item[rating.valueName]);
      const ratingResult = Math.round(
        arrayRating.reduce((partialSum, a) => partialSum + a, 0) /
          arrayRating.length
      );

      return {
        value: ratingResult,
        label: rating.label,
      };
    }

    return ratingTypes.map(calculate);
  }

  function calculateOverallRating(ratingArr) {
    const arrayRating = ratingArr.map((item) => item.value);
    return Math.round(
      arrayRating.reduce((partialSum, a) => partialSum + a, 0) /
        arrayRating.length
    );
  }

  const ratingArr = comments && calculateRating();
  const overallRating = (comments && calculateOverallRating(ratingArr)) || 0;

  return (
    <div className="reviews-container">
      {comments && (
        <>
          {ratingArr.map((item, index) => (
            <GradesCircle value={item.value} label={item.label} key={index} />
          ))}
          <div className="total-grades">
            <div className="total">{overallRating}</div>
            <div className="total-count-comments">
              {comments.length} Reviews
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewsContainer;
