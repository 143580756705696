import React from "react";
import { profileIcon, socialInstagram, socialTelegram } from "../Icons";
import { useSelector } from "react-redux";
import { selectUserId } from "../../selectors";

const Header = () => {
  let location = window.location;
  const navigateToProfile = () => window.location.assign("/profile");

  const navigateToLogin = () => window.location.assign("/login");

  const navigateToMain = () => window.location.assign("/");

  const userID = useSelector(selectUserId);

  return (
    <header className="main-header">
      <div className="logo" onClick={navigateToMain} />
      <div className="header-buttons">
        <div className="social-container">
          <a
            href="https://www.instagram.com/enduric.co"
            target="_blank"
            referrerPolicy="no-referrer"
            className="social-button"
            rel="noreferrer"
          >
            {socialInstagram}
          </a>
          <a
            href="https://t.me/enduric_news"
            target="_blank"
            referrerPolicy="no-referrer"
            className="social-button"
            rel="noreferrer"
          >
            {socialTelegram}
          </a>
        </div>
        {userID ? (
          <i className="profile-link" onClick={navigateToProfile}>
            {profileIcon}
          </i>
        ) : (
          // <></>
          <div className="menu">
            <button className="enduric-button red" onClick={navigateToLogin}>
              log in
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
