import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import store from "../src/store";
import { Provider } from "react-redux";
import Routing from "./components/Routing";
import Layout from "./components/Layout";
import { ThemeProviders } from "./mui.providers";
import Initialize from "./components/Initialize";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProviders>
      <Initialize>
        <Layout>
          <Routing />
        </Layout>
      </Initialize>
    </ThemeProviders>
  </Provider>
);
