import React from "react";

const EnduricInput = ({
  label,
  placeholder,
  onChange,
  type,
  value,
  id,
  ariaLabel,
  errorMessage,
  cssClassFnc,
  isAlternativeLabel,
  name,
  pattern,
  required=true
}) => {

  const isError = (action) => {
    if (!action) return;

    return action() ? "success" : "error";
  };

  const defaultInputPatterns = {
    email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
    // password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
    name: "^[a-zA-Z ]{2,30}$",
    phone: "^[0-9]{10}$",
    date: "^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$",
    text: ".*",
    none: null
  }

  return (
    <section
      className={`enduric-input-container ${
        isAlternativeLabel ? "alternative-label" : ""
      }`}
    >
      <label form="label">{label}</label>
      <input
        pattern={pattern ? pattern : defaultInputPatterns[type]}
        name={name}
        value={value}
        required={required}
        placeholder={placeholder}
        className={`${"enduric-input " + isError(cssClassFnc)}`}
        aria-label={ariaLabel}
        id={id}
        type={type}
        onChange={onChange}
      />
      {/*<span className="error-text">{errorMessage}</span>*/}
    </section>
  );
};

export default EnduricInput;
