import React, { useRef, useState, useEffect } from "react";
import EnduricInput from "../../components/EnduricInput";
import { useSelector, useDispatch } from "react-redux";
import { backArrowIcon, editIcon, deleteIcon } from "../../components/Icons";
import {
  selectUserId,
  selectUserInfo,
  selectUpdateProfileInfoStatus,
  selectSessionFromCookies,
} from "../../selectors";
import { get } from '../../utils/api';
import { updateProfileInfo } from "../../redux-slice/async-slice/updateProfileInfo";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../redux-slice/async-slice/getUserInfo";
import each from "lodash/each";
import keys from "lodash/keys";
import { CONFIG } from "../../utils/api/const";
import defaultAvatar from "../../static/images/default-avatar.png";

const EditProfilePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const updateProfileInfoStatus = useSelector(selectUpdateProfileInfoStatus);
  const { token } = useSelector(selectSessionFromCookies);
  const navigate = useNavigate();
  const handleImgLoadingError = (e) => (e.target.src = defaultAvatar);

  let first_mame = useRef();
  let last_name = useRef();
  let nick_name = useRef();
  let club = useRef();
  let gender = useRef();
  let dob = useRef();
  let height = useRef();
  let weight = useRef();
  let country = useRef();
  let city = useRef();

  let [avatars, setAvatars] = useState([]);
  let [imagesUrls, setImagesUrls] = useState([]);

  const setFirstName = (firstNameRef) => (first_mame = firstNameRef);
  const setLastName = (lastNameRef) => (last_name = lastNameRef);
  const setNickName = (nickNameRef) => (nick_name = nickNameRef);
  const setClub = (clubRef) => (club = clubRef);
  const setGender = (genderRef) => (gender = genderRef);
  // const setAge = (ageRef) => (age = ageRef);
  const setHeight = (heightRef) => (height = heightRef);
  const setWeight = (weightRef) => (weight = weightRef);
  const setCountry = (countryRef) => (country = countryRef);
  const setCity = (cityRef) => (city = cityRef);
  const setDob = (dobRef) => (dob = dobRef);

  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const prepareSignS3Url = async (fileName, fileType) => {
    const S3Url = await get(`${CONFIG.API_SERVER}/sign-s3?file-name=` + fileName + '&file-type='+fileType);
    return S3Url;
  };

  const updateAvatarCallback = (e) => {
    setAvatars(e.target.files);
  };

  const pasteUserInfoValues = (valueName, value) => {
    if (value.hasOwnProperty("current") && !value.current) {
      return userInfo[valueName];
    } else {
      return value;
    }
  };

  const updateProfileCallback = async () => {
    setIsLoading(true);
    let response;

    const requestData = {
      user_id: userId,
      first_name: pasteUserInfoValues("first_name", first_mame),
      last_name: pasteUserInfoValues("last_name", last_name),
      nick_name: pasteUserInfoValues("nick_name", nick_name),
      club_name: pasteUserInfoValues("club_name", club),
      // gender,
      country: pasteUserInfoValues("country", country),
      dob: pasteUserInfoValues("dob", dob),
      height: pasteUserInfoValues("height", height),
      weight: pasteUserInfoValues("weight", weight),
      city: pasteUserInfoValues("city", city),
    };

    if (avatars.length > 0) {
      let formData = new FormData();

      formData.append("avatar", avatars[0], avatars[0].name);
      each(keys(requestData), (key) => formData.append(key, requestData[key]));
      response = await dispatch(
        updateProfileInfo({
          payload: { token, requestData: formData, isFormData: true },
        })
      );

      /**
       * Update avatar in S3  and get new avatar url.
       */
      const S3Url = await prepareSignS3Url(response.payload.result.avatar, avatars[0].type);
      await fetch(S3Url.signedRequest, {
        method: 'PUT',
        body: avatars[0],
        headers: {
          'Content-Type': avatars[0].type
        },
      });

    } else {
      response = await dispatch(
        updateProfileInfo({ payload: { token, requestData: requestData } })
      );
    }

    if (response.payload.success === "ok") {
      await dispatch(getUserInfo({ token, userId }));
      navigate("/profile");
    }
  };

  const isUserInfoEmpty = () => Object.keys(userInfo).length === 0;
  useEffect(() => {
    if (avatars.length) {
      const newImageURLs = [];
      const avatarsArray = Array.from(avatars);
      avatarsArray.forEach(image => newImageURLs.push(URL.createObjectURL(image)));
      newImageURLs.push(URL.createObjectURL(avatars.item(0)));
      setImagesUrls(newImageURLs);
    }
  }, [avatars]);

  return (
    <>
      {(isUserInfoEmpty() || isLoading) && <Loading />}
      {!isUserInfoEmpty() && (
        <div className="profile-edit-page">
          {updateProfileInfoStatus === "pending" && <Loading />}
          <section className="navigation-container">
            <div
              className="item back-button"
              onClick={() => navigate("/profile")}
            >
              {backArrowIcon} <span>Back to profile</span>
            </div>
            <div className="item delete-account-button">
              {deleteIcon} <span>Delete Account</span>
            </div>
          </section>
          <section className="edit-profile-container">
            <h3 className="page-title">Edit Profile</h3>
            <div className="edit-avatar-container">
              <div className="preview-avatar">
                {!imagesUrls.length ? (
                  <img
                    src={CONFIG.AVATARS_AWS_S3 + userInfo.avatar}
                    onError={(e) => handleImgLoadingError(e)}
                    className="photo"
                  />
                ) : (
                  <img src={imagesUrls[0]} />
                )}
                <div className="edit-avatar-button">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="avatarChange"
                    multiple
                    accept="image/png, image/jpeg"
                    onChange={updateAvatarCallback}
                  />
                  <label htmlFor="avatarChange">
                    <i className="edit-button">{editIcon}</i>{" "}
                    <span>Edit photo</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="edit-customer-info-container">
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"First Name"}
                placeholder={userInfo.first_name}
                ariaLabel={"First Name"}
                errorMessage={"Please enter a valid First Name"}
                id={"firstName"}
                type={"text"}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Last Name"}
                placeholder={userInfo.last_name}
                ariaLabel={"Last Name"}
                errorMessage={"Please enter a valid Last Name"}
                id={"lastName"}
                type={"text"}
                onChange={(e) => setLastName(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Nick Name"}
                placeholder={userInfo.nick_name}
                ariaLabel={"Nick Name"}
                errorMessage={"Please enter a valid Nick Name"}
                id={"nickName"}
                type={"text"}
                onChange={(e) => setNickName(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Country"}
                placeholder={userInfo.country}
                ariaLabel={"Country"}
                errorMessage={"Please enter a valid Country"}
                id={"country"}
                type={"text"}
                onChange={(e) => setCountry(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"City"}
                placeholder={userInfo.city}
                ariaLabel={"City"}
                errorMessage={"Please enter a valid City"}
                id={"city"}
                type={"text"}
                onChange={(e) => setCity(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Club"}
                placeholder={userInfo.club_name}
                ariaLabel={"Club"}
                errorMessage={"Please enter a valid First Name"}
                id={"club"}
                type={"text"}
                onChange={(e) => setClub(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Gender"}
                placeholder={userInfo.gender}
                ariaLabel={"Gender"}
                errorMessage={"Please enter a valid Gender"}
                id={"gender"}
                type={"text"}
                onChange={(e) => setGender(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Age"}
                placeholder={userInfo.dob}
                ariaLabel={"Age"}
                errorMessage={"Please enter a valid Age"}
                id={"dob"}
                type={"number"}
                onChange={(e) => setDob(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Height"}
                placeholder={userInfo.height}
                ariaLabel={"Height"}
                errorMessage={"Please enter a valid height"}
                id={"height"}
                type={"number"}
                onChange={(e) => setHeight(e.target.value)}
              />
              <EnduricInput
                required={false}
                isAlternativeLabel={true}
                label={"Weight"}
                placeholder={userInfo.weight}
                ariaLabel={"Weight"}
                errorMessage={"Please enter a valid weight"}
                id={"weight"}
                type={"number"}
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>
            <button
              className="enduric-button red"
              onClick={updateProfileCallback}
            >
              Save
            </button>
            <button
              className="enduric-button white"
              onClick={() => navigate("/profile")}
            >
              Cancel
            </button>
          </section>
        </div>
      )}
    </>
  );
};

export default EditProfilePage;
