import React, { useEffect, useState } from "react";
import { addToUpcomingIcon, removeFromUpcomingIcon } from "../../Icons";
import { post } from "../../../utils/api";
import { CONFIG } from "../../../utils/api/const";
import { useDispatch, useSelector } from "react-redux";
import { selectSessionFromCookies, selectUpcomingEvents, selectUserId } from "../../../selectors";
import Loading from "../../Loading/Loading";
import { getUpcomingEvents } from "../../../redux-slice/async-slice/getUpcomingEvents";

const UpcomingToggle = ({ event_id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [inYourUpcoming, setInYourUpcoming] = useState(false);
  const userId = useSelector(selectUserId);
  const upcomingEvents = useSelector(selectUpcomingEvents);
  const { token } = useSelector(selectSessionFromCookies);

  const isUpcoming = (events) => {
    const result = events.find((event) => event.event_id === event_id);
    return !!result;
  };

  useEffect(() => {
    if (!event_id) return;

    setInYourUpcoming(isUpcoming(upcomingEvents));
  }, [event_id, upcomingEvents]);

  const addToUpcomingRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    await post(`${CONFIG.API_SERVER}/toggleAssignUserToEvent`, {
      user_id: userId,
      event_id: event_id,
    }, false,token);
    dispatch(getUpcomingEvents({ token, userId }));
    setLoading(false);
  };

  if (!userId) {
    return null;
  }

  return (
    <div
      className="upcoming-toggle-component add-to-upcoming"
      onClick={addToUpcomingRequest}
    >
      {loading && <Loading />}
      {inYourUpcoming ? removeFromUpcomingIcon : addToUpcomingIcon}
    </div>
  );
};

export default UpcomingToggle;
