import { useDispatch, useSelector } from "react-redux";
import { setSessionToken } from "../redux-slice/sessionSlice";
import { getUserId } from "../redux-slice/async-slice/getUserId";
import getCookie from "../utils/getCookies";
import { useEffect } from "react";
import { getUserInfo } from "../redux-slice/async-slice/getUserInfo";
import { getUpcomingEvents } from "../redux-slice/async-slice/getUpcomingEvents";
import { selectUserId } from "../selectors";

const Initialize = ({ children }) => {
  const dispatch = useDispatch();
  const token = getCookie("token");

  dispatch(setSessionToken({ token }));
  dispatch(getUserId(token));

  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (userId) {
      dispatch(getUserInfo({ token, userId }));
      dispatch(getUpcomingEvents({ token, userId }));
    }
  }, [userId]);

  return children;
};

export default Initialize;
