import React from "react";
import { Link } from "react-router-dom";

const redirectDescriptionDefault =
  "Hi, you should be logged in to access this page";
const RedirectPage = ({
  patch = "/login",
  redirectDescription = redirectDescriptionDefault,
}) => {
  return (
    <div>
      <h1>{redirectDescription}</h1>
      <br />
      <Link to={patch}>Login page.</Link>
    </div>
  );
};

export default RedirectPage;
