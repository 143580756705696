import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = {
  status: "old",
};

export const updateProfileInfo = createAsyncThunk(
  "updateProfileInfo",
  async ({ payload }) => {
    const response = await post(
      `${CONFIG.API_SERVER}/updateUserInfo`,
      payload.requestData,
      payload.isFormData,
      payload.token
    );
    return response;
  }
);

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled");
}

const updateProfileInfoSlice = createSlice({
  name: "updateProfileInfo",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateProfileInfo.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(updateProfileInfo.fulfilled, (state, action) => {
        return {
          ...state,
          status: "succeeded",
        };
      })
      .addCase(updateProfileInfo.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      })
      .addMatcher(isFulfilledAction, () => {});
  },
});

// this is for configureStore
export default updateProfileInfoSlice.reducer;
