import React from "react";
import { bikeRunHilly, bikeRunRolly, swimLake } from "../../Icons";

import { EVENT_TYPE_DISTANCE } from "../../../consts";

const RaceStages = ({ stagesInfo }) => {
  return (
    <div className="race-distance-stages-info">
      <div className="stage">
        {swimLake}
        <div className="stage-info">
          <span className="stage-type">Swim</span>
          <span className="stage-distance">
            {EVENT_TYPE_DISTANCE[stagesInfo.event_type.toLowerCase()]?.swim}
          </span>
          <span className="stage-place">{stagesInfo.swim_course_profile}</span>
        </div>
      </div>
      <div className="stage">
        {bikeRunRolly}
        <div className="stage-info">
          <span className="stage-type">Bike</span>
          <span className="stage-distance">
            {EVENT_TYPE_DISTANCE[stagesInfo.event_type.toLowerCase()]?.bike}
          </span>
          <span className="stage-place">{stagesInfo.bike_course_profile}</span>
        </div>
      </div>
      <div className="stage">
        {bikeRunHilly}
        <div className="stage-info">
          <span className="stage-type">Run</span>
          <span className="stage-distance">
            {EVENT_TYPE_DISTANCE[stagesInfo.event_type.toLowerCase()]?.run}
          </span>
          <span className="stage-place">{stagesInfo.run_course_profile}</span>
        </div>
      </div>
    </div>
  );
};

export default RaceStages;
