import React from "react";

import { gradesStarEmpty, gradesStarFull, gradesStarHalf } from "../Icons";
import { map } from "lodash/collection";

const GradesStars = ({ grades }) => {
  const starsArray = [];
  let rating = grades;

  for (let i = 5; i > 0; i--) {
    if (rating > 0 && rating >= 1) {
      starsArray.push(gradesStarFull);
      rating--;
    } else if (rating < 1 && rating > 0) {
      starsArray.push(gradesStarHalf);
      rating--;
    } else if (rating <= 0) {
      starsArray.push(gradesStarEmpty);
      rating--;
    }
  }

  starsArray.reverse();

  return (
    <>
      {map(starsArray, (star) => {
        return <>{star}</>;
      })}

      <span className="grades-value">{grades}</span>
    </>
  );
};

export default GradesStars;
