import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

const initialState = {};

export const getUserInfo = createAsyncThunk("getUserInfo", async ({ token, userId }) => {
  const response = await get(`${CONFIG.API_SERVER}/getProfileDetails`, token, {userId: userId});
  return response;
});

const getUserInfoSlice = createSlice({
  name: "getUserInfo",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserInfo.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          ...action.payload.user
        };
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

export const { setUserInfo } = getUserInfoSlice.actions;
// this is for configureStore
export default getUserInfoSlice.reducer;
