import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../utils/api";
import { CONFIG } from "../../utils/api/const";

export const getUserId = createAsyncThunk("auth/getUserId", async (token) => {
  const response = await get(`${CONFIG.API_SERVER}/getUserId`, token);
  return response;
});

const initialState = null;

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled");
}

const userIdSlice = createSlice({
  name: "userId",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserId.fulfilled, (state, action) => {
        return {
          ...state,
          'id': action.payload.userId
        };
      })
      .addMatcher(isFulfilledAction, () => {});
  },
});

// this is for configureStore
export default userIdSlice.reducer;
